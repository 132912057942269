<template>
  <div>
    <custom-alert v-if="!refined.length">
      No bookings found.
    </custom-alert>

    <div v-if="refined.length">
      <div class="d-flex gap-10 mb-5">
        <span>Our records show you were booked through TeacherFinder for the following roles within the specific date period:</span>
        <v-spacer></v-spacer>
        <v-btn @click="downloadCsv()" color="primary">Download CSV</v-btn>
        <v-btn @click="downloadPdf()" color="primary">Download PDF</v-btn>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>School</th>
              <th>Contract Title</th>
              <th>Type</th>
              <th>Position</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="application in refined" :key="application.id">
              <td>{{ $startDate(job(application).contractDates) | downloadDate }}</td>
              <td>{{ $endDate(job(application).contractDates) | downloadDate }}</td>
              <td>{{ school(application).name }}</td>
              <td>{{ job(application).title }}</td>
              <td>{{ job(application).type }}</td>
              <td>{{ job(application).position }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import jsonexport from 'jsonexport'
import moment from 'moment'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'
import { mapState } from 'vuex'

export default {
  props: {
    applications: Array,
    dates: Array,
    schoolFilter: String,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),

    refined: function () {
      let refined = this.applications.filter(application => application.status == 'Hired')
      refined = refined.filter(application => !!this.$store.getters['jobs/jobData'](application.jobId))

      // DATE RANGER FILTER
      if (this.dates && this.dates.length) {
        refined = refined.filter(application => {
          let job = this.job(application)
          let start = this.$startDate(job.contractDates)
          let rangeStart = moment(this.dates[0]).valueOf()
          let rangeEnd = moment(this.dates[1]).valueOf()
          
          return start >= rangeStart && start <= rangeEnd
        })
      }

      // SCHOOL FILTER
      if (this.schoolFilter) {
        refined = refined.filter(application => {
          let school = this.school(application)
          return school.name == this.schoolFilter
        })
      }

      return refined
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    job(application) {
      return this.$store.getters['jobs/jobData'](application.jobId) || {}
    },

    school(application) {
      let job = this.$store.getters['jobs/jobData'](application.jobId)
      return this.$store.getters['schools/userSchool'](job.useruid)
    },

    /*------------------------------------------------------------------------------
     * DOWNLOAD CSV
     *----------------------------------------------------------------------------*/
    downloadCsv() {
      let data = []

      this.refined.forEach(application => {
        data.push({
          'From': this.$options.filters.downloadDate(this.$startDate(this.job(application).contractDates)),
          'To': this.$options.filters.downloadDate(this.$endDate(this.job(application).contractDates)),
          'School': this.school(application).name,
          'Contract Title': this.job(application).title,
          'Type': this.job(application).type,
          'Position': this.job(application).position,
        })
      })

      jsonexport(data, (err, csv) => {
        if (err) return console.error(err)
        else {
          let filename = this.dates ? `report_${moment(this.dates[0]).format('DDMMYYYY')}_to_${moment(this.dates[1]).format('DDMMYYYY')}.csv` : 'report.csv'

          var link = document.createElement("a")
          let csvContent = "data:text/csv;charset=utf-8," + csv
          var encodedUri = encodeURI(csvContent)
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", filename)
          document.body.appendChild(link)
          link.click()
        }
      })
    },

    /*------------------------------------------------------------------------------
     * DOWNLOAD PDF
     *----------------------------------------------------------------------------*/
    downloadPdf() {
      const doc = new jsPDF()
      let filename = this.dates ? `report_${moment(this.dates[0]).format('DDMMYYYY')}_to_${moment(this.dates[1]).format('DDMMYYYY')}` : 'report'
      let data = []
      let img = new Image
      
      img.src = `${window.location.origin}/images/pdf_logo.jpg`
      
      img.onload = () => {
        this.refined.forEach(application => {
          data.push([
            this.$options.filters.downloadDate(this.$startDate(this.job(application).contractDates)),
            this.$options.filters.downloadDate(this.$endDate(this.job(application).contractDates)),
            this.school(application).name,
            this.job(application).title,
            this.job(application).type,
            this.job(application).position
          ])
        })
        
        doc.addImage(img, 'JPEG', 13, 10, 35, 7)

        doc.autoTable({
          head: [['YOUR JOB BOOKINGS REPORT', '']],
          body: [
            [
              { content: `Name: ${ this.user.firstName } ${ this.user.lastName }`},
              { content: `Date Range: ${ this.dateRanges() }`, styles: { halign: 'right' } },
            ],
            [ { content: 'Our records show you were booked through TeacherFinder for the following roles within the specific date period: ', colSpan: 2  } ]
          ],
          theme: 'plain',
          startY: 25,
          headStyles: { fontSize: 10 },
          bodyStyles: { fontSize: 7 },
        })
  
        doc.autoTable({
          head: [['From', 'To', 'School', 'Contract Title', 'Type', 'Position']],
          body: data,
          theme: 'plain',
          headStyles: { fontSize: 7 },
          bodyStyles: { fontSize: 7 },
        })
  
        doc.save(`${filename}.pdf`)
      }
    },

    /*------------------------------------------------------------------------------
     * DATE RANGES
     *----------------------------------------------------------------------------*/
    dateRanges() {
      return this.dates ? `${moment(this.dates[0]).format('DD/MM/YYYY')} to ${moment(this.dates[1]).format('DD/MM/YYYY')}` : ''
    }
  }
}
</script>