<template>
  <div class="flex-grow-1">
    <AdminTitle title="Your Job Bookings Report">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-sheet v-if="schools && schools.length" width="250">
            <v-select
              v-model="school"
              :items="schools"
              placeholder="Select School"
              hide-details
              full-width
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            width="100%"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dates"
                :rules="[rules.required]"
                placeholder="Select Dates"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                hide-details
                full-width
                readonly
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              @change="getReport()"
              no-title
              range
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </AdminTitle>

    <div>
      <preloader v-if="status.gettingEmployerJobs" />
      <Bookings v-if="!status.gettingEmployerJobs" :applications="applications" :schoolFilter="school" :dates="dates" />
    </div>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import Bookings from './Bookings'
import _ from 'lodash'

export default {
  metaInfo: {
    title: 'Reports'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      menu: null,
      dates: null,
      school: null,
      options: [
        { text: 'Confirmed Bookings List View', value: 'list' },
        { text: 'Job Applicant Shortlist', value: 'applicant' }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Bookings
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.jobs.status,
      applications: (state) => state.apply.appliedJobs,
    }),

    schools: function () {
      let schools = []
      let refined = this.applications.filter(application => application.status == 'Hired')
      refined = refined.filter(application => !!this.$store.getters['jobs/jobData'](application.jobId))

      refined.forEach(application => {
        let job = this.$store.getters['jobs/jobData'](application.jobId)
        if (this.$store.getters['jobs/jobData'](application.jobId)) {
          let school = this.$store.getters['schools/userSchool'](job.useruid)
          if (school) schools.push(school.name)
        }
      })

      schools = _.uniq(schools)
      schools = schools.filter(school => !!school)

      return _.uniq(schools)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('apply', ['getApplied']),

    getReport() {
      this.$refs.menu.save(this.dates)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getApplied()
  }
}
</script>